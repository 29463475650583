import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Modal } from "bootstrap";
import { ref, get, query, orderByChild, equalTo } from "firebase/database";
import db from "../../database";
import { orderBy } from "lodash";

import PageContainer from "../common/PageContainer";
import PageTitle from "../common/PageTitle";
import NewsHighlight from "./NewsHighlight";
import NewsDetail from "./NewsDetail";
import ImagePlaceholder from "../../assets/Aliaga_Home.jpg";
import styles from "./news.module.css";
import "react-lazy-load-image-component/src/effects/blur.css";

const getContent = (item) =>
  item.body.split("\\n").map((meti, windex) => (
    <p key={`${item.title}-${windex}`}>
      {meti.match(/[^\]]+(?![^<]*\])/g)}
      <a
        target="_blank"
        rel="noreferrer"
        href={meti.match(/(?<=\[)(.*?)(?=\])/g)}
      >
        {meti.match(/(?<=<)(.*?)(?=>)/g)}
      </a>
    </p>
  ));

class News extends React.Component {
  state = {
    selected: null,
    // queryId: '',
    // modal: false,
    routeState: null,
    fetchedNews: [],
    isSmall: false,
    isExtraLarge: false,
    highlight: null,
  };

  componentDidMount() {
    this.fetchNews().then((res) => {
      if (res) {
        const { fetchedNews } = this.state;
        const modalElement = document.getElementById("newsModal");
        const query = this.useQuery();
        const queryId = query.get("id");
        const { location, history } = this.props;
        const { routeState } = this.state;
        this.setState({ routeState: location.state });
        if (modalElement) {
          // Agregar evento hidden a modal
          modalElement.addEventListener("hide.bs.modal", () => {
            // Modificar url
            if (!routeState) {
              history.push(location.pathname);
            }
          });
          // Abrir seleccionado en query al cargar
          if (queryId) {
            const selectedNewsFromQuery = fetchedNews.find(
              (item) => item.id === queryId
            );
            if (selectedNewsFromQuery) {
              this.setState({
                selected: {
                  ...selectedNewsFromQuery,
                  content: getContent(selectedNewsFromQuery),
                },
                queryId: selectedNewsFromQuery.id,
              });
              const newsModal = new Modal(modalElement);
              newsModal.show();
            }
          }
        }
      }
    });
    const isSmall = window.matchMedia("(max-width: 767px)").matches;
    const isExtraLarge = window.matchMedia("(min-width: 1400px)").matches;
    this.setState((state) => ({
      ...state,
      isSmall,
      isExtraLarge,
    }));
    window.addEventListener("resize", () => {
      const isSmall = window.matchMedia("(max-width: 767px)").matches;
      const isExtraLarge = window.matchMedia("(min-width: 1400px)").matches;
      this.setState((state) => ({
        ...state,
        isSmall,
        isExtraLarge,
      }));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, location } = this.props;
    const modalElement = document.getElementById("newsModal");
    const newsModal = Modal.getInstance(modalElement);
    if (
      newsModal &&
      prevProps.location.search !== location.search &&
      prevProps.location.search !== ""
    ) {
      newsModal.hide();
    } else if (
      prevProps.location.search === location.search &&
      location.search === "" &&
      prevProps.location.key !== location.key
    ) {
      // Volver a home
      history.goBack();
    }
  }

  componentWillUnmount() {
    const modalElement = document.getElementById("newsModal");
    const newsModal = Modal.getInstance(modalElement);
    if (newsModal) {
      newsModal.hide();
    }
  }

  useQuery() {
    return new URLSearchParams(this.props.location.search);
  }

  fetchNews = () => {
    const newsRef = query(
      ref(db, `news`),
      orderByChild("published"),
      equalTo(true)
    );
    const value = get(newsRef, `news`)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const news = snapshot.val();
          const newnews = news
            .filter((item) => !!item)
            .map((item) => ({ ...item, newdate: new Date(item.date) }));
          const orderednews = orderBy(newnews, ["newdate"], ["desc"]);
          this.setState({
            fetchedNews: orderednews.slice(1),
            highlight: orderednews[0],
          });
          return orderednews;
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        return error;
      });
    // onValue(newsRef, async(snapshot) => {
    //     const data = await snapshot.val();
    //     return data;
    // });
    return value;
  };

  openModalById(currentNews) {
    const modalElement = document.getElementById("newsModal");
    this.setState({ selected: currentNews, queryId: currentNews.id });
    const queryId = currentNews.id;
    this.props.history.replace(`${this.props.location.pathname}?id=${queryId}`);
    const newsModal = new Modal(modalElement);
    newsModal.show();
  }

  render() {
    const { selected, fetchedNews, isSmall, isExtraLarge, highlight } =
      this.state;
    return (
      <PageContainer>
        <PageTitle title="News" />
        <div className="row">
          <div className="col-12">
            {highlight ? (
              <NewsHighlight highlight={highlight} />
            ) : (
              <p>Loading</p>
            )}
          </div>
        </div>
        <div className="grid gap-3">
          {fetchedNews.map((item) => {
            const currentNews = { ...item, content: getContent(item) };
            return (
              <Fragment key={`news-${item.date.replaceAll("/", "")}`}>
                <div
                  className={`${styles["news-card"]} card g-col-12 g-col-md-6 border-0 rounded-0`}
                  onClick={() => {
                    this.openModalById(currentNews);
                    this.setState({ routeState: null });
                  }}
                >
                  <div className="row g-0 h-100">
                    <div className="col-md-4">
                      <LazyLoadImage
                        alt={item.title}
                        src={item.image ? item.image : ImagePlaceholder}
                        width={"100%"}
                        effect="blur"
                        wrapperClassName="d-flex h-100 overflow-hidden"
                        className={`${styles["lazy-img"]} object-fit-cover`}
                        style={{
                          objectPosition: isSmall
                            ? `${
                                !!item.imageHorizontalPositionSm
                                  ? item.imageHorizontalPositionSm
                                  : "center"
                              } ${
                                !!item.imageVerticalPositionSm
                                  ? item.imageVerticalPositionSm
                                  : "center"
                              }`
                            : isExtraLarge
                            ? `${
                                !!item.imageHorizontalPositionXl
                                  ? item.imageHorizontalPositionXl
                                  : "center"
                              } ${
                                !!item.imageVerticalPositionXl
                                  ? item.imageVerticalPositionXl
                                  : "center"
                              }`
                            : `${
                                !!item.imageHorizontalPosition
                                  ? item.imageHorizontalPosition
                                  : "center"
                              } ${
                                !!item.imageVerticalPosition
                                  ? item.imageVerticalPosition
                                  : "center"
                              }`,
                        }}
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="fs-6">{item.title}</p>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            {item.date}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
          <div
            className="modal fade"
            id="newsModal"
            tabIndex="-1"
            aria-labelledby="newsModalLabel"
            aria-hidden="false"
          >
            <div className="modal-dialog modal-dialog-centered">
              <NewsDetail
                selected={selected}
                ImagePlaceholder={ImagePlaceholder}
                isSmall={isSmall}
                isExtraLarge={isExtraLarge}
              />
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

export default withRouter(News);
